<template>
	<div>
		<header class="line has-background-primary"></header>
		<section class="profile section">
			<div class="container">
				<div class="columns">
					<div class="column is-two-fifths">
						<h1 class="title is-size-1">{{ billing.year }}</h1>
						<p class="subtitle">{{ billing.month }}</p>
					</div>
					
					<div class="column has-text-black-light has-text-right-in-desktop box is-size-7 is-5 is-desktop">
						<div class="columns is-2">
							<div class="column is-two-fifths has-text-weight-light has-text-justified has-text-weight-bold">
								<p>Total Facturado </p>
							</div>
							<div class="column has-text-left">
								{{ total }}
								<!-- {{ record.id_remu? record.id_remu:'Sin Información' }} -->
							</div>
						</div>
						<div class="columns is-2 has-text-justified">
							<div class="column is-two-fifths has-text-weight-light has-text-weight-bold">
								Total Recaudado
							</div>
							<div class="column has-text-left">
								{{ totalRaised }}
								<!-- {{ record.id_conta? record.id_conta:'Sin Información' }} -->
							</div>
						</div>
						<div v-if="totalRaised===0" class="columns is-12">
							<div class="column has-text-right">
								<button class="button is-danger is-small" @click.prevent="eventModalDelete()"><span class="icon is-small"><i class="fa fa-trash"></i></span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="modal" id="modalConfirmDelete">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
				<p class="modal-card-title">Eliminar Facturación</p>
				<button class="delete"  @click.prevent="eventModalDelete()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					Estás por eliminar el periodo. Estás seguro?
				</section>
				<footer class="modal-card-foot has-text-centered inline-block">
					<button class="button is-danger" @click.prevent="deleteBilling()">Eliminar</button>
					<button class="button" @click.prevent="eventModalDelete()">Cancel</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	components: {
		
	},
  data: () => ({ 
		billing: { 
			id: '',
			year: '',
			month: '',
		}
	}),
	props: {
		billingProp: Object,
		billings: Array,
		total: Number,
		totalRaised: Number
	},
	watch: { 
		billingProp: {
			handler(newValue) {
				this.billing = newValue
			}
		}
	},
	methods: {
		eventModalDelete () {
      const Modal = document.querySelector(`#modalConfirmDelete`)
      Modal.classList.toggle('is-active')
    },
		async deleteBilling () {
			await this.$axios
			.post('billing/delete', { billing: this.billing })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.$router.push('/billings')
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.error(error))
		}
	},
	computed: { }
	
}
</script>