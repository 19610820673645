<template>
  <div class="container">    
    <Billing :billingProp="this.billing" :total="invoicesTotal" :totalRaised="totalRaised"/>
    <input
      v-model="searchText"
      class="input"
      type="text"
      placeholder="Búsqueda por Rut o Nombre"
    >
		<Invoices :invoices="this.invoices" @delete-invoice-company="emitDelInvoice" @add-invoice-company="addInvoiceCompany" />
    <div id="confirm-del-modal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <div class="modal-card-body">
          <div class="box">
            <p>Quitar cliente del proceso?</p>
            <!-- Your content -->
          </div>
        </div>
        <footer class="modal-card-foot">
          <button class="button is-success" @click.prevent="toDel()">Sí</button>
          <button class="button" @click.prevent="confirmModal()">Cancelar</button>
        </footer>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</template>
<script>
import Billing from '@/components/billing/billing.vue'
import Invoices from '@/components/billing/invoices'
export default {
  name: 'BillingView',
  components: {
    Billing,
		Invoices
  },
  data: () => ({
    billing: { id: 0, year: '', month: '' },
    billings: [],
		invoices: [],
		invoicesTotal: 0,
		totalRaised: 0,
    searchText: '',
    invoiceIdToDelete: 0
  }),
  methods: {
    async getBilling() {
      const billingId = this.$route.params.id
      this.billing.id = billingId
      await this.$axios
      .get('billing/billingGet', { params: { billingId: this.billing.id } })
      .then(response => {
				this.billing = response.data.billing
				const newDate = new Date(this.billing.year, this.billing.month - 1, 1)
				const monthName = newDate.toLocaleString('es-cl', { month: 'long' })
				this.billing.month = monthName.charAt(0).toUpperCase() + monthName.slice(1)
      })
      .catch(error => console.log(error))
    },
		async getBillings() {
			const billingId = this.$route.params.id
			await this.$axios
      .get('billing/invoicesGet', { params: { billingId: billingId, searchParam: this.searchText } })
      .then(response => {
				this.invoices = response.data.invoices
				let total = 0
				let raised = 0
				this.invoices.map(item => {
					total += item.total
				})
				this.invoices.filter(item => item.paid).map(item => {
					raised += item.total
				})
				this.invoicesTotal = total
				this.totalRaised = raised
      })
      .catch(error => console.log(error))
		},
    async deleteInvoiceCompany(invoiceCompanyId) {
      this.$axios
      .post('billings/deleteInvoiceCompany', { invoiceCompany: { id: invoiceCompanyId } } )
      .then(response => {
        console.log(response)
        this.confirmModal()
        this.getBillings()

      })
    },
    async addInvoiceCompany(invoiceCompanyId) {
      this.$axios
      .post('billings/addInvoiceCompany', { invoiceCompany: { id: invoiceCompanyId } } )
      .then(response => {
        console.log(response)
        // this.confirmModal()
        this.getBillings()

      })
    },
    confirmModal() {
      const Modal = document.querySelector(`#confirm-del-modal`)
      Modal.classList.toggle('is-active')
    },
    toDel() {
      this.deleteInvoiceCompany(this.invoiceIdToDelete)
    },
    emitDelInvoice(invoiceCompanyId) {
      this.invoiceIdToDelete = invoiceCompanyId
      this.confirmModal()
    }

  },
  mounted() {
		this.getBilling()
		this.getBillings()
	},
  watch: { 
		searchText: function() { // watch it
			this.getBillings()
		}
	}
}
</script>