<template>
<section>
  <div class="container">
    <div v-if="invoices">
      {{ invoices.length > 0? invoices.length: 'No existen facturas' }}
    </div>
    <div class="columns is-multiline">
      <div class="column is-12" v-for="invoice in invoices" :key="invoice.id">
        <div v-if="!invoice.enabled" class="box content is-small has-background-info-light">
          <!-- <router-link :to="{ name: 'BillingView', params: {id: billing.id}}"> -->
            <article class="post">
              <h4>{{ invoice.company_name }}</h4>
              <div class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
												<span class="has-text-weight-bold">
													<i class="fa-solid fa-not-equal"></i>
													Sin Facturar
												</span>
												
                    </p>
                  </div>
                </div>
                <div class="media-right is-medium">
                  <button class="button is-light" @click.prevent="addInvoiceCompany(invoice.id)">
                    <span class="has-text-grey-light">
                      <i class="fa-solid fa-user-plus"></i>
                    </span>
                  </button>
                </div>
              </div>
            </article>
          <!-- </router-link> -->
        </div>
				<div v-else-if="invoice.paid" class="box content is-small has-background-success-light">
          <router-link :to="{ name: 'invoiceCompany', params: {id: invoice.id}}">
            <article class="post">
              <h4>{{ invoice.company_name }}</h4>
              <div class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
                      Total: ${{ formatPrice(invoice.total) }} &nbsp;
												<span class="has-text-weight-bold">
													<i class="fa-solid fa-thumbs-up"></i>
													Pagado
												</span>
												
                    </p>
                  </div>
                </div>
                <div class="media-right is-medium">
									<span class="has-text-grey-light">
										<i class="fa-solid fa-calendar-check"></i>
                  </span>									
                </div>
              </div>
            </article>
          </router-link>
        </div>
				<div v-else class="box content is-small has-background-danger-light">
          <router-link :to="{ name: 'invoiceCompany', params: {id: invoice.id}}">
            <article class="post">
              <h4>{{ invoice.company_name }}</h4>
              <div class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
                      Total Facturado: <strong>${{ formatPrice(invoice.total) }} </strong>&nbsp;
												<span class="has-text-weight-bold">
													<i class="fa-solid fa-clock"></i>
													Pendiente
												</span>
												
                    </p>
                  </div>
                </div>
                <div class="media-right is-medium">
                  <button class="button is-danger is-light" @click.prevent="deleteInvoiceCompany(invoice.id)">
                    <span class="has-text-grey-light">
                      <i class="fa-solid fa-trash"></i>
                    </span>
                  </button>
                </div>
              </div>
            </article>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</section>
</template>
<script>
export default {
  props: {
    invoices: Array,
    hasWritePermission: Boolean
  },
	data: () => ({ }),
	components: { },
  methods: { 
		deleteInvoiceCompany(id) {
			this.$emit('delete-invoice-company', id)
		},
    addInvoiceCompany(id) {
			this.$emit('add-invoice-company', id)
		},
    formatPrice(value) {
			let val = (value/1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
		
	}
}
</script>
